/* eslint-disable import/prefer-default-export */

import $ from 'jquery';

import 'semantic-ui-dropdown/dropdown';
import 'semantic-ui-transition/transition';
import 'semantic-ui-dropdown/dropdown.css';
import 'semantic-ui-transition/transition.css';

import 'jquery-mask-plugin';
import 'inputmask';
import 'inputmask/dist/jquery.inputmask.min';

import React from 'react';
import ReactDOM from 'react-dom';

import './optionsList';
import './formBuilder';
import './hint';
import './dropdown';
import './contactMap';
import './loadMoreNews';
import './navScroll';
import './recaptchaNota';

import './app/app';

import '../sass/index.scss';

import validateForm, { validateFormMiddlename } from './formValidator';
import sendAjax from './sendFormData';
import sendFormStats from './sendFormStats';
import scrollToHeight from './scrollToHeight';
import FileUpload from './FileUpload/FileUpload';

export const NOTA_RECAPTCHA_ID = 'captcha-input';

$(document).ready(() => {
  const $form = $('.fdo-container-filter-form');
  const $requiredFields = $('.fdo-container-filter-input-required');

  const fileUploaderRoot = document.getElementById('fdo-container-filter-file-uploader');

  let files = [];
  let filesError = false;

  const onFilesChange = (uploadFiles) => {
    if (uploadFiles) {
      files = uploadFiles.map((file) => file.id);
    } else {
      files = [];
    }

    filesError = false;
  };

  const onFilesError = () => {
    filesError = true;
  };

  const initFileUploader = () => {
    files = [];

    if (fileUploaderRoot) {
      ReactDOM.unmountComponentAtNode(fileUploaderRoot);

      ReactDOM.render(
        <FileUpload
          onChange={onFilesChange}
          onFileError={onFilesError}
          id="files"
        />,
        fileUploaderRoot,
      );
    }
  };

  initFileUploader();

  $form.on('submit', (e) => {
    e.preventDefault();
    const headers = {};

    function getFormData(form) {
      const submitUrl = form.attr('action');
      const isFeedbackForm = submitUrl === '/api/feedback/v2/frontend/json/ru/support/';
      const data = {};

      if (isFeedbackForm) {
        data.isLegalEntity = false;
      }

      form.find('input, textarea').each((e, item) => {
        const $target = $(item);
        const fieldName = $target.attr('data-title');
        const fieldValue = $target.val();
        const fieldType = $target.attr('type');

        if ($target.attr('id') === NOTA_RECAPTCHA_ID) {
          if (isFeedbackForm) {
            data.captchaCode = $target.attr('data-captcha-code');
          } else {
            headers['Captcha-Code'] = $target.attr('data-captcha-code');
          }

          data.captcha = $target.val();
        } else {
          if (fieldType !== 'submit' && fieldType !== 'radio' && fieldName) {
            if (fieldName.indexOf('custom_') !== -1) {
              data.custom = data.custom || {};
              data.custom[fieldName.substring('custom_'.length)] = fieldValue;
            } else if (fieldName === 'themeId') {
              data[fieldName] = parseInt(fieldValue, 10);
            } else {
              data[fieldName] = fieldValue;
            }
          }

          if (fieldType === 'radio' && $target.is(':checked')) {
            data[fieldName] = fieldValue;
          }

          if (fieldType === 'file') {
            data.files = files.length ? files : [];
          }
        }
      });

      if (isFeedbackForm) {
        data.message = `№ карты: ${data.custom?.cardNumber || 'Не указан'}\nВопрос: ${data.message}`;
      }

      return data;
    }

    function scrollToFirstRequiredEmptyField() {
      const firstFieldError = $('.fdo-container-filter-input-error').eq(0);
      const filesError = $('#FileUploader__message-error');
      const firstRequiredEmptyField = firstFieldError.length ? firstFieldError : filesError;

      if (firstRequiredEmptyField.length) {
        const itemPosY = firstRequiredEmptyField.offset().top;
        const margin = 24;
        const scrollFromTop = itemPosY - margin;

        scrollToHeight(scrollFromTop);
      }
    }

    if (filesError) {
      scrollToFirstRequiredEmptyField();
    } else if (validateForm(e.target, $requiredFields) || validateFormMiddlename()) {
      const url = $(e.target).attr('action');
      const objectData = getFormData($(e.target));
      const data = JSON.stringify(objectData);

      const $submitButton = $(e.target).find('[type=submit]');
      if ($submitButton) {
        $submitButton.prop('disabled', true);
      }

      sendAjax(url, data, e.target, headers, () => {
        if ($submitButton) {
          $submitButton.prop('disabled', false);
        }

        sendFormStats(objectData, e.target);

        initFileUploader();
      });
    } else {
      scrollToFirstRequiredEmptyField();
    }
  });
});
