import 'leaflet.markercluster';
import 'leaflet-sleep';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import DG from '2gis-maps';

import {
  TILE_CONFIG,
  DEFAULT_OPTIONS,
} from '../../../DGmapConfigs';

const DEFAULT_MAP_ZOOM = 13;

// https://github.com/Leaflet/Leaflet.markercluster
const DEFAULT_MARKER_CLUSTER_PARAMS = {
  showCoverageOnHover: false,
  zoomToBoundsOnClick: true,
  spiderfyOnMaxZoom: true,
  removeOutsideVisibleBounds: true,
  chunkedLoading: true,
};

const MARKERS = {
  active: {
    iconUrl: '/assets/karta-moskvicha/assets/map/active.svg',
    iconSize: [46, 62],
    iconAnchor: [23, 62],
  },
  cluster: {
    iconUrl: '/assets/karta-moskvicha/assets/map/cluster.svg',
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  },
};

// Redefine Tile loading servers for remove adv layers MRT-98
DG.config = {
  ...DG.config,
  ...TILE_CONFIG,
};

class Map extends PureComponent {
  static propTypes = {
    style: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    className: PropTypes.string,

    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.number,
    showCenterMarker: PropTypes.bool,
    points: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      coordinates: PropTypes.arrayOf(PropTypes.number.isRequired),
    })),
    markerClusterParams: PropTypes.shape({
      showCoverageOnHover: PropTypes.bool,
      zoomToBoundsOnClick: PropTypes.bool,
      spiderfyOnMaxZoom: PropTypes.bool,
      removeOutsideVisibleBounds: PropTypes.bool,
    }),
  }

  static defaultProps = {
    style: {},
    className: '',
    zoom: DEFAULT_MAP_ZOOM,
    showCenterMarker: false,
    points: [],
    markerClusterParams: DEFAULT_MARKER_CLUSTER_PARAMS,
  }

  componentDidMount() {
    const { mapComponent } = this;
    const {
      center,
      zoom,
      showCenterMarker,
    } = this.props;

    DG.then(() => {
      this.map = DG.map(mapComponent, {
        ...DEFAULT_OPTIONS,
        center,
        zoom,
      }).on('locationerror', (error) => {
        console.log(error)
        document.querySelector('.dg-control-round__icon_name_locate').parentElement.classList.add('disabled');
      });

      this.activeMapIcon = DG.icon({
        ...MARKERS.active,
      });

      if (showCenterMarker) {
        this.centerMarker = DG.marker(
          center,
          {
            interactive: false,
            keyboard: false,
            icon: this.activeMapIcon,
          },
        );
        this.centerMarker.addTo(this.map);
      }
    });
  }

  componentWillUpdate(nextProps) {
    const {
      center,
      showCenterMarker,
      zoom,
      points,
      markerClusterParams,
    } = nextProps;

    if (center !== this.props.center) {
      DG.then(() => {
        this.map.setView(center, zoom);
      });

      if (showCenterMarker) {
        if (this.centerMarker) {
          this.centerMarker.removeFrom(this.map);
          this.centerMarker = undefined;
        }

        this.centerMarker = DG.marker(
          center,
          {
            interactive: false,
            keyboard: false,
            icon: this.activeMapIcon,
          },
        );
        this.centerMarker.addTo(this.map);
      }
    }

    if (points.length !== this.props.points.length) {
      DG.then(() => {
        if (!this.markers) {
          this.markers = DG.markerClusterGroup({
            ...DEFAULT_MARKER_CLUSTER_PARAMS,
            ...markerClusterParams,
          });
        } else {
          this.markers.clearLayers();
        }

        if (!this.isAdded) {
          this.map.addLayer(this.markers);
          this.isAdded = true;
        }

        const clusterIcon = DG.icon({
          ...MARKERS.cluster,
        });

        const pointsArray = [...points.map(({ id, pointClickHandler, coordinates: center }) => {
          const marker = DG.marker(
            center,
            {
              interactive: !!pointClickHandler,
              keyboard: false,
              icon: clusterIcon,
            },
          );

          if (pointClickHandler) {
            marker.on('click', () => pointClickHandler(id));
          }

          return marker;
        })];

        this.markers.addLayers(pointsArray);
        this.markers.refreshClusters();
      });
    }
  }

  render() {
    const { style, className } = this.props;

    return (
      <div
        ref={(c) => { this.mapComponent = c; }}
        style={style}
        className={cn('mos-map', className)}
      />
    );
  }
}

export default Map;
