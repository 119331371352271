import 'whatwg-fetch';
import apiUrl from './apiConstansts';

const methods = ['get', 'post', 'put', 'patch', 'del'];

// TODO: https://www.npmjs.com/package/qs
function objectToQueryString(obj) {
  if (obj) {
    const query = Object.keys(obj)
      .filter(key => obj[key] !== '' && obj[key] !== null)
      .map(key => `${key}=${obj[key]}`)
      .join('&');

    return query.length > 0 ? `?${query}` : undefined;
  }

  return undefined;
}

/**
 * Generate CRUD interface for API
 * @export
 * @class
 * @example
 * client.post('/url/', { params: {id: 1, id2: 2}, data: data })
 * @example
 * client.get(`/url/${id}`)
 * @returns Promise
 * Promise then useing in Redux clientMiddleware.js
 */
export default class {
  constructor() {
    methods.forEach((method) => {
      this[method] = (path, { params, data } = {}) => new Promise(
        (resolve, reject) => {
          const apiUrlString = apiUrl(path);
          const url = params
            ? `${apiUrlString}${objectToQueryString(params)}`
            : apiUrlString;

          const options = {
            method,
            mode: 'cors',
            credentials: 'include',
          };

          fetch(url, options)
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                return resolve(response.json());
              }

              const error = new Error(response.message);
              error.response = response;

              return reject(error);
            });
        });
    });
  }
}
