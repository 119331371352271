import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import Viewport from '@mos-front-components/public/es/components/Viewport'; // eslint-disable-line

import {
  ViewportType,
  FiltersType,
  CardholderTypesType,
} from '../propTypes';

import Map from '../shared/Map/Map';
import Company from '../shared/Company/Company';
import Filters from './Filters/Filters';

import {
  getCardholderTypes,
  getCategories,
  getCompanies,
  getCompany,
  setCurrentCompany,
} from '../store/partners';

import {
  filteredCompaniesStructureSelector,
} from '../store/selectors';


export const WIDTH_MOBILE_BREAKPOINT = 1024; // mobile width in px
export const WIDTH_STOP_SCROLL_BREAKPOINT = 768; // width then Company Card opening in full screen
const BODY_CLASS = 'body-noscroll';

const MOSCOW_CENTER_COORDINATES = [55.755814, 37.617635];
const MOSCOW_ZOOM = 10;

const TRANSITION_PREFIX = 'fade';
const TRANSITION_TIME = 300;

const diffArray = (arr1, arr2) => ([
  ...arr1.filter(item => !arr2.includes(item)),
  ...arr2.filter(item => !arr1.includes(item)),
]);

const FirstChild = (props) => {
  const childrenArray = React.Children.toArray(props.children);

  return childrenArray[0] || null;
};


class PartnersMap extends PureComponent {
  static propTypes = {
    viewport: ViewportType.isRequired,

    getCategories: PropTypes.func.isRequired,
    getCardholderTypes: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,

    filteredCompanies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),

    currentFilteredCompanyIndex: PropTypes.number,
    setCurrentCompany: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,

    filters: FiltersType.isRequired,
    cardholderTypes: CardholderTypesType.isRequired,

    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    filteredCompanies: [],
    currentFilteredCompanyIndex: null,
  }

  componentWillMount() {
    const { getCategories, getCardholderTypes } = this.props;

    getCategories();
    getCardholderTypes();
  }

  componentWillUpdate(nextProps) {
    const {
      filters: {
        cardholderTypes: newCardholderTypes,
        categories: newCategories,
      },
      viewport: { windowWidth },
      filteredCompanies,
      currentFilteredCompanyIndex,
    } = nextProps;
    const {
      getCompanies,
      filters: {
        cardholderTypes,
        categories,
      },
    } = this.props;

    if ((newCardholderTypes.length || newCategories.length) &&
      (cardholderTypes !== newCardholderTypes || categories !== newCategories)
    ) {
      const diffCardholdersTypes = diffArray(cardholderTypes, newCardholderTypes);
      const diffCategories = diffArray(categories, newCategories);

      if (
        diffCardholdersTypes.some(dct => !cardholderTypes.includes(dct))
        || diffCategories.some(dc => !categories.includes(dc))
      ) {
        const getCompaniesQuery = {
          fields: 'geoData,cardTypes,categoryId,minDiscount,maxDiscount,cardholderTypes',
        };

        if (newCardholderTypes && newCardholderTypes.length) {
          getCompaniesQuery.cardholderTypes = newCardholderTypes;
        }
        if (newCategories && newCategories.length) {
          getCompaniesQuery.categories = newCategories;
        }

        getCompanies(getCompaniesQuery);
      }
    }

    const currentCompany = currentFilteredCompanyIndex !== null
      && filteredCompanies[currentFilteredCompanyIndex];

    if (
      currentCompany &&
      currentCompany.loaded &&
      windowWidth < WIDTH_STOP_SCROLL_BREAKPOINT
    ) {
      document.documentElement.classList.add(BODY_CLASS);
    } else {
      document.documentElement.classList.remove(BODY_CLASS);
    }
  }

  pointClickHandler = (id) => {
    const {
      setCurrentCompany,
      getCompany,
      filteredCompanies,
    } = this.props;

    setCurrentCompany(id);

    if (id !== null) {
      const index = filteredCompanies.findIndex(company => company.id === id);

      if (index > -1 && !filteredCompanies[index].loaded) {
        getCompany(filteredCompanies[index].id, filteredCompanies[index].categoryId);
      }
    }
  }

  render() {
    const {
      viewport: { windowWidth },
      filteredCompanies,
      currentFilteredCompanyIndex,
      loading,
      cardholderTypes,
    } = this.props;
    const isMobile = windowWidth < WIDTH_MOBILE_BREAKPOINT;

    const currentCompany = currentFilteredCompanyIndex !== null
      && filteredCompanies[currentFilteredCompanyIndex];

    return (
      <div className="PartnersMap">
        <CSSTransitionGroup
          component={FirstChild}
          transitionName={TRANSITION_PREFIX}
          transitionAppear
          transitionAppearTimeout={TRANSITION_TIME}
          transitionLeave
          transitionLeaveTimeout={TRANSITION_TIME}
          transitionEnter
          transitionEnterTimeout={TRANSITION_TIME}
        >
          {loading &&
            <div className="LoadingOverlay">
              <div className="LoadingOverlay__content" />
            </div>
          }
        </CSSTransitionGroup>


        {(currentCompany && currentCompany.loaded) &&
          <Company
            company={currentCompany}
            showMap={false}
            showDiscountByCardholdersTypes
            rawCardholdersTypes={cardholderTypes}
          />
        }

        <Filters isMobile={isMobile} />

        <div className="Map">
          <Map
            className="Map__container"
            center={MOSCOW_CENTER_COORDINATES}
            zoom={MOSCOW_ZOOM}
            points={filteredCompanies.map(company => ({
              ...company,
              pointClickHandler: this.pointClickHandler,
            }))}
          />
        </div>
      </div>
    );
  }
}

const wrapper = compose(
  connect(
    filteredCompaniesStructureSelector,
    dispatch => bindActionCreators({
      getCategories,
      getCardholderTypes,
      getCompanies,
      getCompany,
      setCurrentCompany,
    }, dispatch),
  ),
  Viewport,
);

export default wrapper(PartnersMap);
