/* eslint-disable import/prefer-default-export */

import $ from 'jquery';
import debounce from 'debounce';

$(document).ready(() => {
  const delay = 200;

  function scrollToActiveMenuItemMobile() {
    const mobileView = 1024;
    const paddingLeft = 16;
    if (window.innerWidth < mobileView) {
      const menu = document.getElementsByClassName('menu')[0];
      const menuActiveItem = document.getElementsByClassName('menu__item_active')[0];
      if (menuActiveItem) {
        menu.scrollLeft = menuActiveItem.offsetLeft - paddingLeft;
      }
    }
  }

  scrollToActiveMenuItemMobile();
  window.onresize = debounce(scrollToActiveMenuItemMobile, delay);
  window.onresize.clear();
});
