const GET_CATEGORIES_START = 'mosru/partners/GET_CATEGORIES_START';
const GET_CATEGORIES_SUCCESS = 'mosru/partners/GET_CATEGORIES_SUCCESS';
const GET_CATEGORIES_ERROR = 'mosru/partners/GET_CATEGORIES_ERROR';

const GET_CARD_HOLDERS_TYPES_START = 'mosru/partners/GET_CARD_HOLDERS_TYPES_START';
const GET_CARD_HOLDERS_TYPES_SUCCESS = 'mosru/partners/GET_CARD_HOLDERS_TYPES_SUCCESS';
const GET_CARD_HOLDERS_TYPES_ERROR = 'mosru/partners/GET_CARD_HOLDERS_TYPES_ERROR';

const GET_COMPANIES_BY_CATEGORY_START = 'mosru/partners/GET_COMPANIES_BY_CATEGORY_START';
const GET_COMPANIES_BY_CATEGORY_SUCCESS = 'mosru/partners/GET_COMPANIES_BY_CATEGORY_SUCCESS';
const GET_COMPANIES_BY_CATEGORY_ERROR = 'mosru/partners/GET_COMPANIES_BY_CATEGORY_ERROR';

const GET_COMPANIES_START = 'mosru/partners/GET_COMPANIES_START';
const GET_COMPANIES_SUCCESS = 'mosru/partners/GET_COMPANIES_SUCCESS';
const GET_COMPANIES_ERROR = 'mosru/partners/GET_COMPANIES_ERROR';

const GET_COMPANY_START = 'mosru/partners/GET_COMPANY_START';
const GET_COMPANY_SUCCESS = 'mosru/partners/GET_COMPANY_SUCCESS';
const GET_COMPANY_ERROR = 'mosru/partners/GET_COMPANY_ERROR';

const SET_CURRENT_CATEGORY = 'mosru/partners/SET_CURRENT_CATEGORY';
const SET_CURRENT_COMPANY = 'mosru/partners/SET_CURRENT_COMPANY';

const SET_FILTERS = 'mosru/partners/SET_FILTERS';

const MIN_DISCOUNT = 1;
export const MAX_DISCOUNT = 30;

export const DEFAULT_FILTERS = {
  discount: {
    min: MIN_DISCOUNT,
    max: MAX_DISCOUNT,
  },
  cardholderTypes: [],
  categories: [],
};


const initialState = {
  loading: false,

  currentCategoryId: -1,
  currentCompanyId: null,

  companies: {},
  categories: [],

  companiesByCategories: {},
  loadedCategoriesIds: [],

  filters: DEFAULT_FILTERS,

  cardholderTypes: [],
};

export default function partners(state = initialState, action) {
  switch (action.type) {
    // GET_CATEGORIES
    case GET_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CATEGORIES_SUCCESS: {
      const { result: { list: categories } } = action;

      return {
        ...state,
        categories,
        loading: false,
      };
    }

    // GET_CARD_HOLDERS_TYPES
    case GET_CARD_HOLDERS_TYPES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_CARD_HOLDERS_TYPES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CARD_HOLDERS_TYPES_SUCCESS: {
      const { result: { list: cardholderTypes } } = action;

      return {
        ...state,
        cardholderTypes,
        loading: false,
      };
    }

    // GET_COMPANIES_BY_CATEGORY
    case GET_COMPANIES_BY_CATEGORY_START:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES_BY_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_COMPANIES_BY_CATEGORY_SUCCESS: {
      const { result: { list }, id } = action;

      // TODO: check id in array before push
      return {
        ...state,
        loadedCategoriesIds: [
          ...state.loadedCategoriesIds,
          id,
        ],
        companiesByCategories: {
          ...state.companiesByCategories,
          [id]: [
            ...list,
          ],
        },
        loading: false,
      };
    }

    // GET_COMPANIES_BY_CATEGORY
    case GET_COMPANIES_START:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_COMPANIES_SUCCESS: {
      const { result: { list } } = action;

      const companies = {};
      const length = list.length;
      for (let index = 0; index < length; index += 1) {
        companies[list[index].id] = list[index];
      }

      return {
        ...state,
        companies: {
          ...state.companies,
          ...companies,
        },
        loading: false,
      };
    }

    // GET_COMPANY
    case GET_COMPANY_START:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        loading: true,
      };

    case GET_COMPANY_SUCCESS: {
      const { result: { item } } = action;

      const companiesByCategoryId = state.companiesByCategories[item.categoryId]
        ? [...state.companiesByCategories[item.categoryId]]
        : [];
      const index = companiesByCategoryId.findIndex(company => company.id === item.id);

      if (index > -1) {
        companiesByCategoryId[index] = {
          ...item,
          loaded: true,
        };
      } else {
        companiesByCategoryId.push(
          {
            ...item,
            loaded: true,
          },
        );
      }

      return item.categoryId
        ? {
          ...state,
          companiesByCategories: {
            ...state.companiesByCategories,
            [item.categoryId]: [
              ...companiesByCategoryId,
            ],
          },
          companies: {
            ...state.companies,
            [item.id]: {
              ...state.companies[item.id],
              ...item,
              loaded: true,
            },
          },
          loading: false,
        }
        : {
          ...state,
          loading: false,
        };
    }


    case SET_CURRENT_CATEGORY: {
      const { id: currentCategoryId } = action;

      return {
        ...state,
        currentCategoryId,
      };
    }

    case SET_CURRENT_COMPANY: {
      const { id: currentCompanyId } = action;

      return {
        ...state,
        currentCompanyId,
      };
    }

    case SET_FILTERS: {
      const { filters } = action;

      return {
        ...state,
        filters,
      };
    }


    default:
      return state;
  }
}

export function getCategories() {
  return {
    types: [GET_CATEGORIES_START, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR],
    promise: client => client.get('category'),
  };
}

export function getCardholderTypes() {
  return {
    types: [
      GET_CARD_HOLDERS_TYPES_START,
      GET_CARD_HOLDERS_TYPES_SUCCESS,
      GET_CARD_HOLDERS_TYPES_ERROR,
    ],
    promise: client => client.get('cardholders'),
  };
}

export function getCompaniesByCategory(id, params) {
  return {
    types: [
      GET_COMPANIES_BY_CATEGORY_START,
      GET_COMPANIES_BY_CATEGORY_SUCCESS,
      GET_COMPANIES_BY_CATEGORY_ERROR,
    ],
    promise: client => client.get(`category/${id}/company`, {
      params,
    }),
    id,
  };
}

export function getCompanies(params) {
  return {
    types: [GET_COMPANIES_START, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR],
    promise: client => client.get('company', {
      params,
    }),
  };
}

export function getCompany(id) {
  return {
    types: [GET_COMPANY_START, GET_COMPANY_SUCCESS, GET_COMPANY_ERROR],
    promise: client => client.get(`company/${id}`),
    id,
  };
}

export function setCurrentCategory(id) {
  return {
    type: SET_CURRENT_CATEGORY,
    id,
  };
}

export function setCurrentCompany(id) {
  return {
    type: SET_CURRENT_COMPANY,
    id,
  };
}

export function setFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}
