import { FILE_UPLOAD_ERRORS } from './enum';

const checkResponseError = (errorResponse) => {
  const { data } = errorResponse?.data || null;

  if (data.length) {
    const firstErrorCode = data[0].error_code;

    if (firstErrorCode.includes('INVALID_MIME_TYPE_ERROR')) {
      return FILE_UPLOAD_ERRORS.FILE_TYPE_NOT_SUPPORTED;
    }

    if (firstErrorCode.includes('TOO_LARGE_ERROR')) {
      return FILE_UPLOAD_ERRORS.FILE_SIZE_LIMIT;
    }
  }

  return FILE_UPLOAD_ERRORS.FILE_TYPE_NOT_SUPPORTED;
};

const checkUploadError = (error) => {
  let errorMessage;

  switch (error.status) {
    case 400:
      errorMessage = checkResponseError(error);
      break;
    case 413:
      errorMessage = FILE_UPLOAD_ERRORS.FILE_SIZE_LIMIT;
      break;
    case 500:
    default:
      errorMessage = FILE_UPLOAD_ERRORS.FILE_UNKNOWN_ERROR;
      break;
  }

  return errorMessage;
};

export default checkUploadError;
