import $ from 'jquery';

import DG from '2gis-maps';

import {
  TILE_CONFIG,
  DEFAULT_OPTIONS,
} from './DGmapConfigs';

const DEFAULT_ZOOM = 16;
const ADDRESS_COORDINATES = [55.725524, 37.624247];

$('document').ready(() => {
  const showMapButton = $('#show-map');

  if (showMapButton) {
    showMapButton.one('click', () => {
      // Redefine Tile loading servers for remove adv layers MRT-98
      DG.config = {
        ...DG.config,
        ...TILE_CONFIG,
      };

      DG.then(() => {
        const map = DG.map('contacts-map', {
          ...DEFAULT_OPTIONS,
          center: ADDRESS_COORDINATES,
          zoom: DEFAULT_ZOOM,
        });

        const icon = DG.icon({
          iconUrl: '/assets/karta-moskvicha/assets/map/active.svg',
          iconSize: [46, 62],
          iconAnchor: [23, 62], // anchor in the middle of the icon bottom
        });

        DG.marker(
          ADDRESS_COORDINATES,
          {
            icon,
            interactive: false,
            keyboard: false,
          },
        ).addTo(map);
      });
    });

    showMapButton.on('click', (e) => {
      const target = $(e.currentTarget);
      target.toggleClass('active');
    });
  }
});
