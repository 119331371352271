/* eslint-disable react/no-array-index-key */

import React from 'react';
import cn from 'classnames';

const WEEK_END = 'выходной';
const ALL_DAY = 'круглосуточно';

const weekDays = [
  'воскресенье',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота',
];


/**
 * @param {Object[]} workingHours - Prepared array of working days of week
 * @param {Object[]} workingDays -  Array of working days of week
 * @param {number} nowDayOfWeek - Today day of week new Date().getDay()
 * @param {number} dayIndex - day index from iterated
 * @returns {string}
 */
const nextWorkDayText = (workingHours, workingDays, nowDayOfWeek, dayIndex) => {
  let workText = '';
  let nextWorkDay = null;

  if (dayIndex < workingHours.length - 1) {
    const endOfArray = workingDays.slice(dayIndex + 1);
    nextWorkDay = endOfArray.find(wh => wh !== null);

    if (!nextWorkDay) {
      const startOfArray = workingDays.slice(0, dayIndex);
      nextWorkDay = startOfArray.find(wh => wh !== null);
    }
  }

  if (nextWorkDay.allDay) {
    workText = `Закрыто до ${nextWorkDay.dayOfWeek}`;
  } else {
    const nextWorkDayIndex = weekDays.findIndex(wd => wd === nextWorkDay.dayOfWeek);
    const daysDiff = nextWorkDayIndex - nowDayOfWeek;

    workText = daysDiff === 1
      ? `Закрыто до ${nextWorkDay.startHours}:${nextWorkDay.startMinutes}`
      : `Закрыто до ${nextWorkDay.dayOfWeek} ${nextWorkDay.startHours}:${nextWorkDay.startMinutes}`;
  }

  return workText;
};

const generateWorkingHours = (workingHours) => {
  const now = new Date();
  const nowDayOfWeek = now.getDay();
  const nowHours = now.getHours();
  const nowMinutes = now.getMinutes();

  let isWorkingNow = true;
  let workText = null;


  const workingDays = workingHours.map((wh) => {
    const { Hours: rawHours, DayOfWeek: dayOfWeek } = wh;

    if (rawHours === ALL_DAY) {
      return {
        dayOfWeek,
        allDay: true,
      };
    }

    const splitHours = rawHours.split('-');
    if (splitHours.length > 1) {
      return {
        startHours: splitHours[0].split(':')[0],
        startMinutes: splitHours[0].split(':')[1],
        finishHours: splitHours[1].split(':')[0],
        finishMinutes: splitHours[1].split(':')[1],
        dayOfWeek,
      };
    }

    return null;
  });

  const workingHoursNode = workingHours.map(({ DayOfWeek: dayOfWeek, Hours: rawHours }, index) => {
    const hours = rawHours.replace('-', ' – ');
    const isSameWeekDay = weekDays[nowDayOfWeek] === dayOfWeek;

    if (isSameWeekDay && !workText) {
      if (rawHours === ALL_DAY) {
        workText = 'Открыто';
      } else if (rawHours === WEEK_END) {
        isWorkingNow = false;
        workText = nextWorkDayText(workingHours, workingDays, nowDayOfWeek, index);
      } else {
        const splitHours = workingDays[index];

        if (splitHours) {
          const {
            startHours,
            startMinutes,
            finishHours: rawFinishHours,
            finishMinutes,
          } = splitHours;

          const finishHours = parseInt(rawFinishHours, 10) === 0 ? 24 : rawFinishHours;

          // Check if not working right now
          if (
            nowHours >= parseInt(finishHours, 10) ||
            nowHours < parseInt(startHours, 10)
          ) {
            if (
              nowMinutes >= parseInt(finishMinutes, 10) ||
              nowMinutes < parseInt(startMinutes, 10)
            ) {
              isWorkingNow = false;
              workText = nextWorkDayText(workingHours, workingDays, nowDayOfWeek, index);
            } else {
              workText = `Открыто до ${rawFinishHours}:${finishMinutes}`;
            }
          } else {
            workText = `Открыто до ${rawFinishHours}:${finishMinutes}`;
          }
        }
      }
    }

    const dayOfWeekClass = cn({
      workingHours__dayOfWeek: true,
      workingHours__dayOfWeek_now: isSameWeekDay,
    });

    return (
      <p
        key={index}
        className="workingHours"
      >
        <span className={dayOfWeekClass}>{dayOfWeek}</span>
        <span className="workingHours__blank">{' '}</span>
        <span className="workingHours__hours">{hours}</span>
      </p>
    );
  });

  return ({
    workingHoursNode,
    workText,
    isWorkingNow,
  });
};


export default generateWorkingHours;
