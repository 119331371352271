import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  compose,
  withState,
} from 'recompose';

import InputRange from 'react-input-range';

import { compareValues } from '../../helpers/helpers';

import {
  FiltersType,
  CardholderTypesType,
} from '../../propTypes';

import {
  filtersStructureSelector,
} from '../../store/selectors';

import {
  setFilters,
  DEFAULT_FILTERS,
} from '../../store/partners';


class Filters extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,

    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    isToggled: PropTypes.bool.isRequired,
    setToggled: PropTypes.func.isRequired,

    selectedCardTypesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setCardTypesIds: PropTypes.func.isRequired,

    setFilters: PropTypes.func.isRequired,

    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),
    cardholderTypes: CardholderTypesType.isRequired,

    filters: FiltersType.isRequired,
  }

  static defaultProps = {
    isMobile: false,
    categories: [],
  }

  discountSizeMessage() {
    const {
      filters,
    } = this.props;

    if (filters.discount.min >= DEFAULT_FILTERS.discount.max) {
      return `${filters.discount.max}% и более`;
    }
    if (filters.discount.max >= DEFAULT_FILTERS.discount.max) {
      return `${filters.discount.min} - ${filters.discount.max}% и более`;
    }
    if (filters.discount.min === DEFAULT_FILTERS.discount.min &&
      filters.discount.max === DEFAULT_FILTERS.discount.max) {
      return `${filters.discount.min} - ${filters.discount.max}% и более`;
    }
    if (filters.discount.min === filters.discount.max) {
      return `${filters.discount.min}%`;
    }
    return `${filters.discount.min} - ${filters.discount.max}%`;
  }

  render() {
    const {
      isMobile,
      isOpen,
      setOpen,
      cardholderTypes,
      categories,
      filters,
      setFilters,
      isToggled,
      setToggled,
      selectedCardTypesIds,
      setCardTypesIds,
    } = this.props;


    // TODO: Ноды вынести в отдельный PureComponent
    const cardholderTypesNode = cardholderTypes.map((cardholderType) => {
      const { id, name } = cardholderType;
      const { cardholderTypes: cardholderTypesFilter } = filters;
      const checked = selectedCardTypesIds.includes(id);

      return (<div
        className="Filters__checkbox"
        key={id}
      >
        <input
          type="checkbox"
          id={`mapFilterCardType_${id}`}
          checked={checked}
          onChange={() => {
            if (checked) {
              setCardTypesIds(selectedCardTypesIds.filter(i => i !== id));

              setFilters({
                ...filters,
                cardholderTypes: cardholderTypesFilter.filter(typeId => typeId !== id),
              });
            } else {
              setCardTypesIds([...selectedCardTypesIds, id]);

              setFilters({
                ...filters,
                cardholderTypes: [...cardholderTypesFilter, id],
              });
            }
          }}
        />

        <label htmlFor={`mapFilterCardType_${id}`}>
          {name}
        </label>
      </div>);
    });

    const categoriesNode = categories
      .sort(compareValues('name'))
      .map((category) => {
        const { id, name } = category;
        const { categories } = filters;
        const checked = categories.includes(id);

        return (<div
          className="Filters__checkbox"
          key={id}
        >
          <input
            type="checkbox"
            id={`mapFilterCategories_${id}`}
            checked={checked}
            onChange={() => {
              if (checked) {
                setFilters({
                  ...filters,
                  categories: categories.filter(catId => catId !== id),
                });
              } else {
                setFilters({
                  ...filters,
                  categories: [...categories, id],
                });
              }
            }}
          />

          <label htmlFor={`mapFilterCategories_${id}`}>
            {name}
          </label>
        </div>);
      });

    return (
      <div
        className={cn({
          Filters: true,
          Filters_isOpen: isOpen,
        })}
      >
        <div className="Filters__range">
          Размер скидки: {this.discountSizeMessage()}

          <InputRange
            allowSameValues
            minValue={DEFAULT_FILTERS.discount.min}
            maxValue={DEFAULT_FILTERS.discount.max}
            value={filters.discount}
            formatLabel={(value, type) => {
              if (type === 'max') {
                return isMobile
                  ? `>${value}%`
                  : `${value}% и более`;
              }

              return `${value}%`;
            }}
            onChange={discount => setFilters({ ...filters, discount })}
          />
        </div>

        <div
          className={cn({
            Filters__block: true,
            Filters__block_active: isToggled,
          })}
        >
          <div
            tabIndex="-1"
            role="button"
            className="Filters__title"
            onClick={() => setToggled(!isToggled)}
          >
            Категории держателей карт
          </div>

          <div className="Filters__list">
            {cardholderTypesNode}
          </div>
        </div>

        <div
          className={cn({
            Filters__block: true,
            Filters__block_active: !isToggled,
          })}
        >
          <div
            tabIndex="-1"
            role="button"
            className="Filters__title"
            onClick={() => setToggled(!isToggled)}
          >
            Где получить скидку
          </div>

          <div className="Filters__list">
            {categoriesNode}
          </div>
        </div>

        <div
          tabIndex="-1"
          role="button"
          onClick={() => {
            setCardTypesIds([]);
            setFilters({ ...DEFAULT_FILTERS });
            setToggled(true);
          }}
          className="Filters__clear"
        >
          Сбросить фильтры
        </div>

        {isMobile &&
          <div
            role="button"
            tabIndex="-1"
            className="Filters__tab"
            onClick={() => setOpen(!isOpen)}
          />
        }
        {' '}
      </div>
    );
  }
}

const wrapper = compose(
  connect(
    filtersStructureSelector,
    dispatch => bindActionCreators({
      setFilters,
    }, dispatch),
  ),
  withState('isOpen', 'setOpen', true),
  withState('isToggled', 'setToggled', true),
  withState('selectedCardTypesIds', 'setCardTypesIds', []),
);

export default wrapper(Filters);
