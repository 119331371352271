import $ from 'jquery';
import getEdsGif from './stats';

const sendStudentsStats = (data, $form) => {
  const {
    lastName: last_name, // eslint-disable-line camelcase
    firstName: first_name, // eslint-disable-line camelcase
    middleName: middle_name, // eslint-disable-line camelcase
    birthday: birth,
    universityCode,
    sex,
  } = data;

  const school = $form.find(`[data-value="${universityCode}"]`);

  const statsData = {
    eventObject: {
      last_name,
      first_name,
      middle_name,
      birth,
      gender: sex === 'male' ? 1 : 0,
      school: school ? school.text() : '',
    },
    eventDst: 'stats',
    eventSrc: 'mos.ru/socialnaya-karta/services-proverka-grazhdanina-v-reestre-studentov/',
    eventType: 'students_reestr',
  };

  getEdsGif(statsData);
};

export default (data, eventTarget) => {
  const $form = $(eventTarget);

  if ($form) {
    const statsKey = $form.attr('data-statsKey');

    switch (statsKey) {
      case 'students':
        sendStudentsStats(data, $form);
        break;

      default:
        break;
    }
  }
}
