import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import axios from 'axios';

import acsAxios from './acsAxios';
import { fileValidation } from './filesValidation';
import { FILE_UPLOAD_API, MB } from './enum';
import checkUploadError from './checkUploadError';

class File extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      progress: 0,
      error: null,
      source: () => null,
      disabled: props.disabled,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      disabled: nextProps.disabled,
    };
  }

  componentDidMount() {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const {
      file,
      onComplete,
      disabled,
      onError,
    } = this.props;

    const error = fileValidation(file);

    this.setState({
      error,
      complete: !!file.id || false,
      source,
      disabled,
    });

    if (error) {
      onError();
      return false;
    }

    const formData = new FormData();
    formData.append('file', file);

    if (file.id) {
      return false;
    }

    const handleResponseError = (responseError) => {
      if (responseError.response) {
        const parseError = checkUploadError(responseError.response);
        this.setState({
          error: parseError,
        });
      } else if (responseError.request) {
        /* eslint-disable no-console */
        console.log(responseError.request);
      } else {
        console.log('Error', responseError.message);
      }
      if (axios.isCancel(responseError)) {
        console.log('Request canceled', responseError.message);
        /* eslint-enable no-console */
      }

      onError();
    };

    acsAxios({
      method: 'post',
      url: `${FILE_UPLOAD_API}/${file.uuid}/`,
      data: formData,
      withCredentials: true,
      onUploadProgress: (p) => {
        this.setState({
          progress: (p.loaded / p.total) * 70,
        });
      },
      cancelToken: source.token,
      headers: {
        'x-caller-id': 'social-card::service',
      },
    })
      .then((response) => {
        const { data } = response;

        if (response.status === 201) {
          this.setState({
            progress: 100,
            complete: true,
          });
          onComplete(file.uuid, data.file || null);
        } else {
          handleResponseError();
        }
      })
      .catch(handleResponseError);

    return false;
  }

  destroy = () => {
    const { source } = this.state;
    const {
      onDestroy,
      file: { id, uuid, fileId },
    } = this.props;

    if (source.cancel) {
      source.cancel('Operation canceled by the user.');
    }

    this.setState({
      complete: false,
      progress: 0,
      error: null,
      source: () => null,
    });

    acsAxios({
      method: 'delete',
      url: `${FILE_UPLOAD_API}/${uuid}/${fileId}/`,
      withCredentials: true,
      headers: {
        'x-caller-id': 'social-card::service',
      },
    });

    onDestroy(id || uuid);
  };

  render() {
    const {
      error,
      complete,
      progress,
      disabled,
    } = this.state;

    const {
      file: { name, size },
    } = this.props;

    const MainClasses = cn({
      FileUpload_uploaded: true,
      FileUpload_error: error,
      FileUpload_disabled: disabled,
    });

    if (error) {
      return (
        <div className={MainClasses}>
          <div className="FileUpload__FileName">{name}</div>
          <div
            className="FileUpload__Error"
            role="button"
            onClick={this.destroy}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <div className="FileUpload__ErrorText">{error}</div>
          </div>
        </div>
      );
    }

    if (complete) {
      return (
        <div className={MainClasses}>
          <i className="FileUpload__FileIcon" />

          <div className="FileUpload__FileName">{name}</div>

          <div className="FileUpload__size">
            {(size / MB).toFixed(2)}
            MB
          </div>

          {!disabled && (
            <div
              className="FileUpload__RemoveIcon"
              role="button"
              onClick={this.destroy}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              Удалить
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={MainClasses}>
        <div className="FileUpload__FileName">{name}</div>

        <div className="FileUpload__size">
          {(size / MB).toFixed(2)}
          MB
        </div>

        <div className="FileUploadProgress__container">
          <div
            className="FileUploadProgress__indicator"
            style={{ width: `${progress}%` }}
          />
        </div>

        <div
          className="FileUpload__RemoveIconCross"
          role="button"
          onClick={this.destroy}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          Отменить
        </div>
      </div>
    );
  }
}

File.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
    uuid: PropTypes.string,
    fileId: PropTypes.string,
    size: PropTypes.number,
  }),
  disabled: PropTypes.bool.isRequired,
  onError: PropTypes.func,
};

File.defaultProps = {
  file: {
    name: '',
    error: '',
    id: '',
    uuid: '',
    fileId: '',
  },
  onError: () => {},
};

export default File;
