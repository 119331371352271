import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Viewport from '@mos-front-components/public/es/components/Viewport';

import Categories from './Categories/Categories';
import Companies from './Companies/Companies';
import { ViewportType } from '../propTypes';

import { getCategories } from '../store/partners';


export const WIDTH_MOBILE_BREAKPOINT = 768; // mobile width in px

class Partners extends PureComponent {
  static propTypes = {
    getCategories: PropTypes.func.isRequired,
    viewport: ViewportType.isRequired,
  }

  componentWillMount() {
    const { getCategories } = this.props;

    getCategories();
  }

  render() {
    const { viewport: { windowWidth } } = this.props;

    return (
      <div className="Partners">
        <Categories />

        {windowWidth >= WIDTH_MOBILE_BREAKPOINT &&
          <Companies />
        }
      </div>
    );
  }
}

const wrapper = compose(
  connect(
    null,
    dispatch => bindActionCreators({
      getCategories,
    }, dispatch),
  ),
  Viewport,
);

export default wrapper(Partners);
