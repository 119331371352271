import $ from 'jquery';

// classes
const FORM_FIELD_ERROR = 'fdo-container-filter-input-error';

function isNameFieldValid(name) {
  const $name = name.val();
  const invalidPhrase = /(нет|данных|отсутствует)/gmi;

  return !$name.match(invalidPhrase);
}

export function validateFormMiddlename() {
  let formValid = true;
  const $middleNameInput = $('#fdo-container-filter-input-middlename');
  const $middleNameField = $middleNameInput.parent('.fdo-container-filter-input');
  if ($middleNameInput && !isNameFieldValid($middleNameInput)) {
    $middleNameField.addClass(FORM_FIELD_ERROR);
    formValid = false;
  }
  return formValid;
}

export default function validateForm(form, required) {
  let formValid = true;

  function validatePhoneNumber(phone) {
    const $phone = phone.val();
    const $phoneNumbers = $phone.match(/\d/g, '');
    const $phoneLabel = phone.siblings('label');
    if ($phoneNumbers) {
      if ($phoneNumbers.length <= 10) {
        $phoneLabel.text('Укажите действительный номер телефона');
        phone.parent().addClass(FORM_FIELD_ERROR);

        return false;
      }

      if ($phoneNumbers.length > 10) {
        $phoneLabel.text('Телефон');
        phone.parent().removeClass(FORM_FIELD_ERROR);
      }
    }
    return true;
  }

  // Code used from http://javascriptkit.com/script/script2/validatedate.shtml
  function isValidDate(day) {
    // Basic check for format validity
    const validformat = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    let returnval = true;

    if (!validformat.test(day.val())) {
      returnval = false;
    } else {
      // Detailed check for valid date ranges
      const dayfield = parseInt(day.val().split('.')[0], 10);
      const monthfield = parseInt(day.val().split('.')[1], 10);
      const yearfield = parseInt(day.val().split('.')[2], 10);
      const dayobj = new Date(yearfield, monthfield - 1, dayfield);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const currentDateWithoutMilliseconds = new Date(currentYear, currentMonth, currentDay);
      const oneHundredYearsOldDate = new Date(1900, 0);

      if ((dayobj.getDate() !== dayfield)
        || (dayobj.getMonth() + 1 !== monthfield)
        || (dayobj.getFullYear() !== yearfield)) {
        returnval = false;
      }
      // Check if today or tomorrow date
      if (dayobj >= currentDateWithoutMilliseconds) {
        returnval = false;
      }

      if (yearfield < oneHundredYearsOldDate.getFullYear()) {
        returnval = false;
      }

      if (!returnval) {
        day.siblings('label').text('Укажите действительную дату');
      }
    }

    return returnval;
  }

  const dateAttrErrorPlaceholder = 'data-errorplaceholder';

  required.each((index, item) => {
    const $target = $(item);
    const $input = $target.find('input');
    const $textarea = $target.find('textarea');

    if ($input.length && $input.val().length <= 0) {
      if ($input.attr(dateAttrErrorPlaceholder)) {
        $target.find('label')
          .html($input.attr(dateAttrErrorPlaceholder));
      }
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($textarea.length && $textarea.val().length <= 0) {
      if ($textarea.attr(dateAttrErrorPlaceholder)) {
        $target.find('label')
          .html($textarea.attr(dateAttrErrorPlaceholder));
      }
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($input.data('title') === 'lastName' && !isNameFieldValid($input)) {
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($input.data('title') === 'firstName' && !isNameFieldValid($input)) {
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if (
      ($input.attr('type') === 'checkbox' || $input.attr('type') === 'radio')
      && !$input.is(':checked')
    ) {
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($input.data('title') === 'birthday' && !isValidDate($input)) {
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($input.data('title') === 'phoneNumber' && !validatePhoneNumber($input)) {
      $target.addClass(FORM_FIELD_ERROR);
      formValid = false;
    }

    if ($target.hasClass('fdo-container-filter-dropdown') && !$input.val()) {
      if ($input.attr(dateAttrErrorPlaceholder)) {
        $target.find('label, .text')
          .html($input.attr(dateAttrErrorPlaceholder));
      }
    }

    if ($target.hasClass('fdo-container-filter-captcha')) {
      const $notaRecaptchaError = $target.next();
      const $notaRecaptcha = $target.find('.nota-captcha-input');

      if ($notaRecaptchaError.hasClass('fdo-container-captcha-error')) {
        if ($notaRecaptcha.val() === '') {
          $notaRecaptchaError.show();
        } else {
          $notaRecaptchaError.hide();
        }
      }
    }
  });

  return formValid;
}
