import validateFileTypeByName from './fileValidateMIMEType';
import { FILE_UPLOAD_ERRORS, MAX_FILE_SIZE } from './enum';

export const fileValidation = (file) => {
  if (!file) {
    return false;
  }

  if (!file.name) {
    return false;
  }

  if (file.size === 0) {
    return FILE_UPLOAD_ERRORS.FILE_EMPTY;
  }

  if (file.size > MAX_FILE_SIZE) {
    return FILE_UPLOAD_ERRORS.FILE_SIZE_LIMIT;
  }

  if (!validateFileTypeByName(file)) {
    return FILE_UPLOAD_ERRORS.FILE_TYPE_NOT_SUPPORTED;
  }

  return false;
};

export default fileValidation;
