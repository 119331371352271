import $ from 'jquery';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

$(document).ready(() => {
  const tooltip = document.querySelectorAll('[data-tippy-content]');

  tippy(tooltip, {
    placement: 'top',
    allowHTML: true,
    arrow: true,
    hideOnClick: 'toggle',
    interactive: true,
    theme: 'soc',
    trigger: 'click',
    zIndex: 1000,
  });
});
