import $ from 'jquery';

export default function scrollToHeight(height) {
  const mosHeaderHeight = $('#mos-header').outerHeight() || 0;

  const scrollHeight = height - mosHeaderHeight;

  $('html, body').animate({
    scrollTop: `${scrollHeight}px`,
  }, 200);
}
