import $ from 'jquery';

const DROPDOWN_ACTIVE = 'mos-dropdown_active';

$(document).ready(() => {
  const $dropDown = $('.mos-dropdown');

  $dropDown.on('click', (e) => {
    const $target = $(e.currentTarget);
    const $dropDownBox = $target.siblings('.mos-dropdown__list');

    $target.toggleClass(() => {
      if ($target.hasClass(DROPDOWN_ACTIVE)) {
        $dropDownBox.slideUp();
        $target.removeClass(DROPDOWN_ACTIVE);

        return '';
      }

      $dropDownBox.slideDown();

      return DROPDOWN_ACTIVE;
    });
  });
});
