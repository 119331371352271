import $ from 'jquery';
import getNotaCaptcha from './recaptchaNota';

// classes
const FORM_NOT_EMPTY = 'ng-not-empty';
const FORM_FIELD_ERROR = 'fdo-container-filter-input-error';
const FORM_INPUT_FOCUSED = 'fdo-container-filter-label-focused';
const BODY_NOSCROLL_CLASS = 'body-noscroll';
const AGREEMENT_POPUP_OPENED_CLASS = 'agreement-popup_opened';
const SOC_HINT_OPENED = 'soc-hint_opened';
const SCROLLABLE_DROPDOWN = 'scrollable';
const MAX_DROPDOWN_LIST_HEIGHT = 200;

const $formFieldInput = $('.fdo-container-filter-input');
const $formFieldRadio = $('.fdo-container-filter-radio');
const $formFieldTextarea = $('.fdo-container-filter-textarea');

$(document).ready(() => {
  const $dropDown = $('#dropdown');
  const $dropDownLabel = $dropDown.find('label');
  const $dropDownInput = $dropDown.find('input');
  const $dropDownDefaultText = $dropDown.find('.text');
  const $dropDownList = $dropDown.find('.menu');
  const $dropDownClearButton = $dropDown.find('.clear-suggest');

  $dropDown.not('.search').toggleClass(SCROLLABLE_DROPDOWN, $dropDownList.height() >= MAX_DROPDOWN_LIST_HEIGHT);

  $dropDown.dropdown({
    onShow: () => {
      $dropDownLabel.addClass(FORM_INPUT_FOCUSED);
    },
    onHide: () => {
      if ($dropDownInput.val().length !== 0) {
        $dropDownInput.parents('.fdo-container-filter-input').removeClass(FORM_FIELD_ERROR);
        $dropDownInput.addClass(FORM_NOT_EMPTY);
        $dropDownInput.siblings('label').html($dropDownInput.attr('data-defaulttext'));
      }

      if ($dropDownInput.val().length === 0) {
        $dropDownLabel.removeClass(FORM_INPUT_FOCUSED);
        $dropDown.find('.search').removeClass(FORM_NOT_EMPTY);
      }
      $dropDownDefaultText.removeClass('filtered');
      $dropDownClearButton.removeClass('clear-suggest_show');
    },
    fullTextSearch: 'exact',
    message: {
      noResults: 'Попробуйте изменить название учебного заведения',
    },
  });

  $dropDown.find('.search').on('keyup', (e) => {
    const $target = $(e.currentTarget);
    $dropDownClearButton.toggleClass('clear-suggest_show', $target.val().length > 0);
  });

  $dropDownClearButton.on('click', (e) => {
    $dropDown.dropdown('clear');
    // This little trigger trick resets filtered items after search input is cleared
    // Unfortunately, there were no option in semantic ui library to clear filter
    $dropDown.find('.search').val('').trigger('click');
    $dropDownClearButton.removeClass('clear-suggest_show');
    $dropDownInput.removeClass(FORM_NOT_EMPTY);
  });

  const $formInputField = $('.simple-input-validation');

  $formInputField.each((e, item) => {
    const inputMask = $(item).attr('data-mask');
    const inputPattern = $(item).attr('data-pattern');
    const inputPlaceholder = $(item).attr('data-placeholder');
    $(item).mask(inputMask, {
      translation: {
        0: { pattern: inputPattern },
      },
      placeholder: inputPlaceholder,
    });
  });

  const $formLetterField = $('.simple-word-validation');

  $formLetterField.each((e, item) => {
    const inputMask = $(item).attr('data-mask');
    const inputPattern = $(item).attr('data-pattern');
    const inputPlaceholder = $(item).attr('data-placeholder');
    $(item).mask(inputMask, {
      translation: {
        A: {
          pattern: inputPattern,
        },
      },
      placeholder: inputPlaceholder,
    });
  });

  const $surnameField = $('#fdo-container-filter-input-surname');
  const $nameField = $('#fdo-container-filter-input-name');
  const $middlenameField = $('#fdo-container-filter-input-middlename');

  const $regexOnlyLetters = /[А-Яа-яA-Za-zЁё]/gi;
  const $regexOnlyLettersAndDotInTheEnd = /[А-Яа-яA-Za-zЁё.]/gi;

  function cutStringFromEnd(string) {
    const foundLetters = string.match($regexOnlyLettersAndDotInTheEnd);
    const lastIndexOfLetter = string.lastIndexOf(foundLetters[foundLetters.length - 1]);

    if (string.length !== lastIndexOfLetter) {
      return string.slice(0, lastIndexOfLetter + 1);
    }

    return string;
  }

  function clearNameOnBlur(e) {
    const $target = $(e.target);

    $target.val($target.val().trim());

    if (!$target.val().match($regexOnlyLetters)) {
      $target.val('');
    }

    // If one of the names starts not with letter
    if ($target.val().length && $target.val().search($regexOnlyLetters) > 0) {
      $target.val($target.val().slice($target.val().search($regexOnlyLetters)));
    }

    if ($target.val().length) {
      const cuttedStringFromTheEnd = cutStringFromEnd($target.val());
      $target.val(cuttedStringFromTheEnd);
    }

    const $stringCleanedFromDuplicates = $target.val().replace(/([^A-Za-zА-Яа-яЁё])\1{1,}/g, '$1');
    $target.val($stringCleanedFromDuplicates);
  }

  $surnameField.on('blur', clearNameOnBlur);
  $nameField.on('blur', clearNameOnBlur);
  $middlenameField.on('blur', clearNameOnBlur);

  const $birthdateField = $('.birthdate-validation');
  const $birthdateFieldMask = $birthdateField.attr('data-mask');
  const $birthdateFieldPattern = $birthdateField.attr('data-pattern');
  const $birthdateFieldPlaceholder = $birthdateField.attr('data-placeholder');

  $birthdateField.mask($birthdateFieldMask, {
    translation: {
      D: { pattern: $birthdateFieldPattern },
      M: { pattern: $birthdateFieldPattern },
      Y: { pattern: $birthdateFieldPattern },
    },
    placeholder: $birthdateFieldPlaceholder,
  });

  const $phoneField = $('#fdo-container-filter-input-phone');
  $phoneField.on('blur', (e) => {
    const $target = $(e.target);

    if ($target.val().length <= 0) {
      $target.siblings('label').text($target.siblings('label').attr('data-text'));
      $target.siblings('label').removeClass('fdo-container-filter-label-error');
    }
  });

  const $emailField = $('#fdo-container-filter-input-email');
  if ($emailField) {
    $emailField.inputmask({
      regex: '^[A-Za-zА-яЁё0-9._%+-]+@?[A-Za-zА-яЁё0-9.-]+.[A-Za-zА-яЁё]+$',
      placeholder: '',
      jitMasking: true,
    });
  }

  const agreementLink = $('#agreement-link');
  const agreementPopup = $('#agreement-popup');
  const agreementCloseButton = $('.agreement__close');

  agreementLink.on('click', () => {
    agreementPopup.addClass(AGREEMENT_POPUP_OPENED_CLASS);
    $('html').addClass(BODY_NOSCROLL_CLASS);
  });

  agreementCloseButton.on('click', () => {
    agreementPopup.removeClass(AGREEMENT_POPUP_OPENED_CLASS);
    $('html').removeClass(BODY_NOSCROLL_CLASS);
    agreementLink.find(`.${SOC_HINT_OPENED}`).removeClass(SOC_HINT_OPENED);
  });

  function formFieldNotEmpty() {
    if (!$(this).val()) {
      $(this).removeClass(FORM_NOT_EMPTY);
    }
  }

  function removeFieldErrorMessageOnKeyUp() {
    const $target = $(this);
    if ($target.val().length > 0) {
      $target.addClass(FORM_NOT_EMPTY);
      $target.parent().removeClass(FORM_FIELD_ERROR);
      $target.siblings('label').html($target.attr('data-defaulttext'));
    }
  }

  $formFieldInput.find('input').on('keyup', removeFieldErrorMessageOnKeyUp);

  $formFieldInput.find('input').on('blur', formFieldNotEmpty);

  $formFieldRadio.find('label').find('span').on('click', () => {
    $formFieldRadio.removeClass(FORM_FIELD_ERROR);
  });

  $formFieldTextarea.find('textarea').on('blur', formFieldNotEmpty);

  $formFieldTextarea.find('textarea').on('keyup', removeFieldErrorMessageOnKeyUp);

  getNotaCaptcha();
});
