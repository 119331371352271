import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import cn from 'classnames';

import Viewport from '@mos-front-components/public/es/components/Viewport';
import { compareValues } from '../../helpers/helpers';

import Companies from '../Companies/Companies';
import { WIDTH_MOBILE_BREAKPOINT } from '../../Partners/Partners';
import { ViewportType } from '../../propTypes';

import {
  setCurrentCategory,
  getCompaniesByCategory,
} from '../../store/partners';

import {
  categoriesStructureSelector,
} from '../../store/selectors';


class Categories extends PureComponent {
  static propTypes = {
    setCurrentCategory: PropTypes.func.isRequired,
    getCompaniesByCategory: PropTypes.func.isRequired,

    currentCategoryId: PropTypes.number,
    loadedCategoriesIds: PropTypes.arrayOf(PropTypes.number),

    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,

    loading: PropTypes.bool.isRequired,

    viewport: ViewportType.isRequired,
  }

  static defaultProps = {
    currentCategoryId: null,
    loadedCategoriesIds: [],
  };

  componentDidUpdate(prevProps) {
    const { categories } = this.props;

    if (categories && categories.length > 0 && categories !== prevProps.categories) {
      this.setCurrentCategory(categories[0].id);
    }
  }

  setCurrentCategory(id) {
    const {
      currentCategoryId,
      loadedCategoriesIds,
      setCurrentCategory,
      getCompaniesByCategory,
      loading,
    } = this.props;

    if (currentCategoryId !== id) {
      const isId = id !== undefined && id !== -1;

      const alreadyLoaded = isId
        ? loadedCategoriesIds.includes(id)
        : false;

      if (isId && !alreadyLoaded) {
        getCompaniesByCategory(id, { fields: 'address,name' });
      }

      if (!loading) {
        setCurrentCategory(id);
      }
    } else {
      setCurrentCategory(undefined);
    }
  }

  render() {
    const {
      currentCategoryId,
      categories,
      viewport: { windowWidth },
    } = this.props;

    const categoryClassNames = flag => cn({
      Categories__item: true,
      Categories__item_active: flag,
    });

    const isMobile = windowWidth < WIDTH_MOBILE_BREAKPOINT;

    const categoriesNodes = categories
      .sort(compareValues('name'))
      .map(cat => (
        <li
          key={cat.id}
          className={categoryClassNames(currentCategoryId === cat.id)}
        >
          <div
            className="Categories__item-title"
            role="menuitem"
            tabIndex="-1"
            onClick={() => this.setCurrentCategory(cat.id)}
          >
            {cat.name}
          </div>
          {(isMobile && currentCategoryId === cat.id) &&
            <Companies />
          }
        </li>
      ));

    const categoriesList = [
      ...categoriesNodes,
    ];

    return (
      <div className="Categories">
        <ul className="Categories__list">
          {categoriesList}
        </ul>
      </div>
    );
  }
}

const wrapper = compose(
  connect(
    categoriesStructureSelector,
    dispatch => bindActionCreators({
      getCompaniesByCategory,
      setCurrentCategory,
    }, dispatch),
  ),
  Viewport,
);

export default wrapper(Categories);
