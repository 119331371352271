import $ from 'jquery';

const HEAD_ACTIVE_CLASS = 'options__head_active';
const SECTION_ACTIVE_CLASS = 'options__section_active';
const SECTION_CLASS = '.options__section';
const HEAD_CLASS = '.options__head';


$(document).ready(() => {
  const $optionsList = $('.options__list');

  if ($optionsList) {
    const $optionsHead = $optionsList.find(HEAD_CLASS);

    $optionsHead.on('click', (e) => {
      const $target = $(e.currentTarget);

      if ($target.hasClass(HEAD_ACTIVE_CLASS)) {
        $target
          .removeClass(HEAD_ACTIVE_CLASS)
          .next(SECTION_CLASS).removeClass(SECTION_ACTIVE_CLASS);
      } else {
        $target
          .addClass(HEAD_ACTIVE_CLASS)
          .next(SECTION_CLASS).addClass(SECTION_ACTIVE_CLASS);
      }
    });
  }
});
