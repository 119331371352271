import $ from 'jquery';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';

import Partners from './Partners/Partners';
import PartnersMap from './PartnersMap/PartnersMap';


$(document).ready(() => {
  const mosPartners = document.getElementById('mos-partners');

  if (mosPartners) {
    const initialState = {};
    const store = configureStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <Partners />
      </Provider>,
      mosPartners,
    );
  }


  const mosPartnersMap = document.getElementById('mos-partners-map');

  if (mosPartnersMap) {
    const initialState = {};
    const store = configureStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <PartnersMap />
      </Provider>,
      mosPartnersMap,
    );
  }
});
