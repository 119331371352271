/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const ViewportType = PropTypes.shape({
  windowWidth: PropTypes.number.isRequired,
});

const FiltersType = PropTypes.shape({
  discount: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  cardholderTypes: PropTypes.arrayOf(PropTypes.number),
  categories: PropTypes.arrayOf(PropTypes.number),
});

const CardholderTypesType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}));


export {
  ViewportType,
  FiltersType,
  CardholderTypesType,
};
