import { applyMiddleware, compose, createStore } from 'redux';
import clientMiddleware from './middleware/clientMiddleware';
import createReducer from './reducers';

import ApiClient from '../helpers/ApiClient';

const client = new ApiClient();

export default function configureStore(initialState) {
  const middlewares = [clientMiddleware(client)];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
  /* eslint-enable */

  return createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );
}
