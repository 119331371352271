import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  compose,
  withState,
} from 'recompose';

import Viewport from '@mos-front-components/public/es/components/Viewport';
import { ViewportType } from '../../propTypes';

import Company from '../../shared/Company/Company';
import { compareValues } from '../../helpers/helpers';

import {
  companiesStructureSelector,
  currentCategorySelector,
} from '../../store/selectors';

import {
  getCompany,
  setCurrentCompany,
} from '../../store/partners';

const WIDTH_MOBILE_BREAKPOINT = 768; // mobile width in px
const BODY_CLASS = 'body-noscroll';
const COMPANIES_PER_PAGE = 5;

const keyCompanies = [
  'vtb',
  'mib',
  'sberbank',
  'vostok',
  'diksi',
  'mir-torgovli',
  '366',
  'gorzdrav',
  'vdnh',
  'park-gorkogo',
  'karibiya',
  'megafon',
];

const isMobile = ({ viewport: { windowWidth } }) => windowWidth < WIDTH_MOBILE_BREAKPOINT;
const getCurrentCompany = ({ companies, currentCompanyIndex }) => (
  currentCompanyIndex !== null && companies[currentCompanyIndex]
);


class Companies extends PureComponent {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),

    currentCategory: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),

    currentCompanyIndex: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    setCurrentCompany: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,

    startingIndex: PropTypes.number.isRequired,
    setStartingIndex: PropTypes.func.isRequired,

    viewport: ViewportType.isRequired, // eslint-disable-line react/no-unused-prop-types
  }

  static defaultProps = {
    companies: [],
    currentCategory: null,
    currentCompanyIndex: null,
  }

  componentWillUpdate(nextProps) {
    const currentCompany = getCurrentCompany(nextProps);

    if (
      currentCompany &&
      currentCompany.loaded &&
      isMobile(nextProps)
    ) {
      document.documentElement.classList.add(BODY_CLASS);
    } else {
      document.documentElement.classList.remove(BODY_CLASS);
    }
  }

  setCurrentCompany(id) {
    const {
      getCompany,
      setCurrentCompany,
      companies,
      currentCategory,
    } = this.props;

    if (currentCategory) {
      setCurrentCompany(id);

      const index = companies.findIndex(company => company.id === id);

      if (index > -1 && !companies[index].loaded) {
        getCompany(companies[index].id, currentCategory.id);
      }
    }
  }

  getCompaniesList() {
    const {
      companies,
      startingIndex,
      setStartingIndex,
    } = this.props;

    const currentCompany = getCurrentCompany(this.props);
    const endingIndex = isMobile(this.props)
      ? startingIndex + COMPANIES_PER_PAGE
      : companies.length;

    const companiesList = companies
      .sort(compareValues('name'))
      .slice(startingIndex, endingIndex)
      .map(company => (
        <li
          key={company.id}
          role="menuitem"
          tabIndex="-1"
          onClick={() => this.setCurrentCompany(company.id)}
          className={cn({
            Companies__item: true,
            Companies__item_active: currentCompany
              && currentCompany.id === company.id,
          })}
        >
          <div className="Companies__item-title">
            {company.name}
          </div>

          <div className="Companies__item-subtitle">
            {company.address}
          </div>
        </li>
      ));

    const navListLink = (flag = 'prev') => {
      let newStartingIndex = 0;
      let linkText = '';

      if (flag === 'prev') {
        newStartingIndex = startingIndex - COMPANIES_PER_PAGE <= 0
          ? 0
          : startingIndex - COMPANIES_PER_PAGE;

        linkText = newStartingIndex === 0
          ? 'Предыдущие партнёры'
          : `Предыдущие ${COMPANIES_PER_PAGE} партнёров`;
      } else {
        newStartingIndex = startingIndex + COMPANIES_PER_PAGE >= companies.length
          ? companies.length
          : startingIndex + COMPANIES_PER_PAGE;

        linkText = companies.length - newStartingIndex < COMPANIES_PER_PAGE
          ? `Ещё ${companies.length - newStartingIndex} партнёров`
          : `Ещё ${COMPANIES_PER_PAGE} партнёров`;
      }

      return (<li
        role="menuitem"
        tabIndex="-1"
        onClick={() => setStartingIndex(newStartingIndex)}
        className={cn({
          Companies__pagination: true,
          Companies__pagination_prev: flag === 'prev',
          Companies__pagination_next: flag === 'next',
        })}
      >
        <div className="Companies__pagination-text">
          {linkText}
        </div>
      </li>);
    };

    return isMobile(this.props)
      ? [
        startingIndex < COMPANIES_PER_PAGE || navListLink('prev'),
        ...companiesList,
        startingIndex + COMPANIES_PER_PAGE >= companies.length || navListLink('next'),
      ]
      : companiesList;
  }

  render() {
    const {
      currentCategory,
    } = this.props;

    if (!currentCategory) {
      return null;
    }

    const currentCompany = getCurrentCompany(this.props);
    const companiesList = this.getCompaniesList();

    return (
      <div className="companies-wrapper">
        {(currentCompany && currentCompany.loaded) &&
          <Company company={currentCompany} />
        }

        <div className="Companies">
          <h4 className="h4 Companies__header">{currentCategory.name}</h4>
          <ul className="Companies__list">
            {companiesList}
          </ul>
        </div>

      </div>
    );
  }
}

const wrapper = compose(
  connect(
    store => ({
      ...companiesStructureSelector(store),
      currentCategory: currentCategorySelector(store),
    }),
    dispatch => bindActionCreators({
      getCompany,
      setCurrentCompany,
    }, dispatch),
  ),
  withState('startingIndex', 'setStartingIndex', 0),
  Viewport,
);

export default wrapper(Companies);
