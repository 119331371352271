import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  uploadedFiles,
  pushFileIdFromFileList,
  destroyFileFromFileList,
  filesSerialization,
} from './private/helpers';
import { MAX_OVERALL_FILES_SIZE } from './private/enum';

import File from './private/File';

import './FileUpload.scss';

class FileUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || [],
      disabledUploader: !!props.files && props.files.length >= props.maxCount,
      allFilesSizeLimitExceeded: !!(props.files?.length && props.files
        .reduce((acc, file) => acc + file.size, 0) > MAX_OVERALL_FILES_SIZE),
    };
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props;

    if (prevProps.files !== files) {
      if (!files || !files.length) {
        this.setState({
          files: [],
        });
      }

      this.setState((state) => {
        const fileIds = state.files.map(
          (file) => file.id || file.fileId || file.uuid,
        );
        let newFiles = files || [];
        newFiles = newFiles.filter(
          (file) => !fileIds.includes(file.id || file.uuid),
        );

        return {
          files: [...state.files.filter((file) => file.uuid), ...newFiles],
        };
      });
    }
  }

  handleUpload = (event) => {
    const { files } = this.state;
    const { maxCount, onFileError } = this.props;
    const allFilesSize = files.reduce((acc, file) => acc + file.size, 0);
    const serializedFile = filesSerialization(event.target.files);
    const allFilesSizeExceeded = allFilesSize + serializedFile[0].size > MAX_OVERALL_FILES_SIZE;

    if (allFilesSizeExceeded) {
      this.setState({
        allFilesSizeLimitExceeded: true,
      });

      onFileError();

      return;
    }

    const updateFilesList = [
      ...files,
      ...serializedFile,
    ];

    if (updateFilesList.length > maxCount) {
      updateFilesList.length = maxCount;
    }

    this.setState({
      files: updateFilesList,
      disabledUploader: updateFilesList.length >= maxCount,
      allFilesSizeLimitExceeded: false,
    });
  };

  handleDestroy = (fileUuid) => {
    const { files } = this.state;
    const updateFilesList = destroyFileFromFileList(
      [...files],
      fileUuid,
    );

    this.handleSetStateFiles(updateFilesList);
  };

  handleCompleteFile = (fileUuid, fileId) => {
    const { files } = this.state;
    const updateFilesList = pushFileIdFromFileList(
      [...files],
      fileUuid,
      fileId,
    );

    this.handleSetStateFiles(updateFilesList);
  };

  handleSetStateFiles = (files) => {
    const { maxCount, onFileError } = this.props;
    const allFilesSizeLimitExceeded = !!(files.length && files
      .reduce((acc, file) => acc + file.size, 0) > MAX_OVERALL_FILES_SIZE);

    this.setState(
      {
        files,
        disabledUploader: files.length >= maxCount,
        allFilesSizeLimitExceeded,
      },
      this.handleChange,
    );

    if (allFilesSizeLimitExceeded) {
      onFileError();
    }
  };

  handleChange = () => {
    const { files } = this.state;
    const { onChange } = this.props;
    const uploadedFilesArray = uploadedFiles(files);

    if (uploadedFilesArray.length) {
      onChange(uploadedFilesArray);
    } else {
      onChange(null);
    }
  };

  render() {
    const {
      error,
      onFileError,
      buttonPlaceholder,
      id,
      disabled,
      maxCount,
    } = this.props;
    const { files, disabledUploader, allFilesSizeLimitExceeded } = this.state;

    const ButtonClasses = cn('InputUpload', {
      InputUpload_disabled: disabled || disabledUploader,
      InputUpload_emptyFile: error,
    });

    const renderFiles = () => (files && files.length
      ? files.map((file) => (
        <File
          key={file.uuid || file.id}
          file={file}
          onComplete={this.handleCompleteFile}
          onDestroy={this.handleDestroy}
          onError={onFileError}
          disabled={disabled}
        />
      ))
      : null);

    return (
      <div className="FileUpload">
        {renderFiles()}

        {disabledUploader && (
          <div id="FileUploader__message-error" className="Message Message_smallerText Message_type_warning Message_bordered FileUploader__message">
            Достигнут лимит загружаемых файлов.
          </div>
        )}

        {!!allFilesSizeLimitExceeded && (
          <div id="FileUploader__message-error" className="Message Message_smallerText Message_type_error Message_bordered FileUploader__message">
            Превышен общий размер загружаемых файлов. Не более 20 МБ.
          </div>
        )}

        <div className={ButtonClasses}>
          <label htmlFor={id}>
            {buttonPlaceholder}
            <input
              type="file"
              id={id}
              value=""
              data-title={id}
              multiple
              disabled={disabled || disabledUploader}
              onChange={this.handleUpload}
            />
          </label>
        </div>
        <div className="FileUpload__Note">
          <p>
            Не более
            {' '}
            {maxCount}
            {' '}
            файлов, размер файла не может превышать 15 МБ,
            а общий максимальный размер вложений — 20 МБ.
          </p>
          <p>
            Допустимые форматы: DOC, DOCX, XLS, XLSX, PPT, PPTX, PDF, JPG,
            JPEG, BMP, PNG, TIF, GIF.
            {/* , HEIC/HEIF */}
          </p>
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  buttonPlaceholder: PropTypes.string,
  maxCount: PropTypes.number,
  error: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onFileError: PropTypes.func,
  disabled: PropTypes.bool,
};

FileUpload.defaultProps = {
  buttonPlaceholder: 'Прикрепить файл',
  maxCount: 10,
  error: false,
  disabled: false,
  files: [],
  onFileError: () => {},
};

export default FileUpload;
