/* globals L */
/* eslint-disable import/prefer-default-export */

export const TILE_CONFIG = {
  tileServer: '//tile{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd&layerType=nc',
  retinaTileServer: '//tile{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_hd&layerType=nc',
  poiMetaServer: '//tile{s}.maps.2gis.com/poi?x={x}&y={y}&z={z}&v=1&ts=online_sd&layerType=nc',
  retinaPoiMetaServer: '//tile{s}.maps.2gis.com/poi?x={x}&y={y}&z={z}&v=1&ts=online_hd&layerType=nc',
};

export const DEFAULT_OPTIONS = {
  // минимальный зум
  minZoom: 4,
  // maxBounds - Границы РФ
  // Если свойство установлено, карта ограничивает область просмотра согласно
  // заданным географическим границам, "отбрасывая" пользователя назад,
  // если он пытается выйти за пределы установленных границ, а также не позволяет
  // уменьшить масштаб так, чтобы можно было просмотреть неразрешенные участки карты.
  maxBounds: [
    [77.642391, 17.680822], // topLeft
    [40.132586, 168.119958], // bottomRight
  ],
  // Active Points of interests
  poi: false,
  // leaflet-sleep options
  sleepNote: false,
  wakeMessage: null,
  fullscreenControl: false,
  locationControl: true,
  sleepOpacity: 0.85,
  // https://github.com/CliffCloud/Leaflet.Sleep/blob/master/Leaflet.Sleep.js#L80
  sleepButton: () => new L.Control.SleepMapControl({
    prompt: '',
    styles: {},
    position: 'bottomleft',
  }),

};
