import $ from 'jquery';


$(document).ready(() => {
  const button = $('#load-more-news');
  const numberOfNewsToShow = 4;

  if (button) {
    button.on('click', () => {
      let newsListHidden = $('.mos-news__item_hidden').slice(0, numberOfNewsToShow);
      if (newsListHidden.length) {
        newsListHidden.each(() => {
          newsListHidden.removeClass('mos-news__item_hidden');
        });
        newsListHidden = $('.mos-news__item_hidden');
        if (!newsListHidden.length) button.hide();
      }
    });
  }
});

