
export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare

    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, ERROR] = types;

    next({ ...rest, type: REQUEST });

    const actionPromise = promise(client);

    actionPromise.then(
      response => next({ ...rest, result: response.data, type: SUCCESS }),
      error => next({ ...rest, error, type: ERROR }),
    ).catch((error) => {
      console.error('MIDDLEWARE ERROR:', error);
      // dispatch(addMessage('error', error.message));

      return next({ ...rest, error, type: ERROR });
    });

    return actionPromise;
  };
}
