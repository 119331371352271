import uuidv1 from 'uuid/v1';

export const uploadedFiles = (files) => files
  .filter((file) => file.fileId || file.id)
  .map((file) => ({ name: file.name, id: file.fileId || file.id }));

export const pushFileIdFromFileList = (files, fileUuid, fileId) => files.map((item) => {
  const file = item;
  if (file.uuid === fileUuid) {
    file.fileId = fileId;
  }
  return file;
});

export const destroyFileFromFileList = (files, fileUuid) => files
  .filter((item) => (item.id || item.uuid) !== fileUuid);

export const filesSerialization = (files) => {
  const validationFiles = [];

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    file.uuid = uuidv1();
    file.complete = false;
    validationFiles.push(file);
  }

  return validationFiles;
};
