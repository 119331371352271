const STATS_GIF_URL = 'https://stats.mos.ru/eds.gif?o=';

let mosId = null;

/**
 * @returns {number}
 */
const getTimestamp = () => +new Date();

/**
 * @returns {string}
 */
const getMosId = () => {
  mosId =
    mosId ||
    document.cookie.replace(
      /(?:(?:^|.*;\s*)mos_id\s*=\s*([^;]*).*$)|^.*$/,
      '$1',
    ) || null;
  return mosId;
};

const convertDataToSendable = data =>
  encodeURIComponent(JSON.stringify({
    ...data,
    mosId: getMosId(),
    eventTime: getTimestamp(),
  }));

/**
 * @param {object} data
 * @returns {XMLHttpRequest}
 */
const getEdsGif = (data) => {
  const dataToSend = convertDataToSendable(data);

  const img = new Image();
  img.src = `${STATS_GIF_URL}${dataToSend}`;
};

export default getEdsGif;
