import $ from 'jquery';

import { servicesFormResponse, checkStatusFormResponse } from './servicesFormResponse';

export default function sendAjax(url, data, form, headers, callback) {
  const serviceCode = url.split('/').slice(-1)[0];
  const isCheckStatusCurrentCard = serviceCode === 'check-card-status';
  const getFormResponse = (result) => {
    if (isCheckStatusCurrentCard) {
      checkStatusFormResponse(result, form);
    } else {
      servicesFormResponse(result.data || result, serviceCode, form);
    }

    if (callback) callback();
  };

  $.ajax({
    type: 'POST',
    data,
    url,
    contentType: 'application/json',
    dataType: 'json',
    headers,
  }).done((result) => {
    getFormResponse(result);
  }).fail((result) => {
    getFormResponse(result);
  });
}
