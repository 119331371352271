export const FILE_UPLOAD_ERRORS = {
  FILE_SIZE_LIMIT: 'Файл не должен превышать 15 МБ',
  FILE_OVERALL_SIZE_LIMIT: 'Общий максимальный размер вложений не должен превышать 20 МБ',
  FILE_TYPE_NOT_SUPPORTED: 'Недопустимый формат файла',
  FILE_NOT_SELECTED: 'Не выбран файл',
  FILE_VALIDATION_FAILED: 'Данные не должны быть пустыми',
  FILE_UNKNOWN_ERROR: 'Сервер недоступен. Попробуйте позже',
  FILE_EMPTY: 'Нельзя прикладывать пустой файл',
};

export const PERMITTED_FILE_TYPES = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'jpg',
  'jpeg',
  'bmp',
  'png',
  'tif',
  'gif',
  // 'heif',
  // 'heic',
];

export const MB = 1048576;
export const MAX_FILE_SIZE = MB * 15; // 15 mb
export const MAX_OVERALL_FILES_SIZE = MB * 20; // 20 mb

export const DEFAULT_FETCH_OPTIONS = {
  mode: 'same-origin',
  credentials: 'include',
};

export const FILE_UPLOAD_API = '/api/feedback/v2/frontend/json/ru/attachment';
