import { createSelector, createStructuredSelector } from 'reselect';
import { MAX_DISCOUNT } from './partners';

const partnersStateSelector = state => state.partners;

// Base selectors
const currentCategoryIdSelector = createSelector(
  partnersStateSelector,
  partners => partners.currentCategoryId,
);
const loadingSelector = createSelector(
  partnersStateSelector,
  partners => partners.loading,
);
const currentCompanyIdSelector = createSelector(
  partnersStateSelector,
  partners => partners.currentCompanyId,
);
const categoriesSelector = createSelector(
  partnersStateSelector,
  partners => partners.categories,
);
const companiesSelector = createSelector(
  partnersStateSelector,
  partners => partners.companies,
);
const loadedCategoriesIdsSelector = createSelector(
  partnersStateSelector,
  partners => partners.loadedCategoriesIds,
);
const companiesByCategoriesSelector = createSelector(
  partnersStateSelector,
  partners => partners.companiesByCategories,
);
const cardholderTypesSelector = createSelector(
  partnersStateSelector,
  partners => partners.cardholderTypes,
);
const filtersSelector = createSelector(
  partnersStateSelector,
  partners => partners.filters,
);


// Computed selectors
const currentCategorySelector = createSelector(
  categoriesSelector,
  currentCategoryIdSelector,
  (categories, currentCategoryId) => {
    if (currentCategoryId) {
      return categories.find(category => category.id === currentCategoryId);
    }

    return null;
  },
);

const companiesByCategorySelector = createSelector(
  currentCategoryIdSelector,
  companiesByCategoriesSelector,
  (categoryId, companiesByCategories) => {
    if (categoryId && companiesByCategories && companiesByCategories[categoryId]) {
      return companiesByCategories[categoryId];
    }

    return [];
  },
);

const currentCompanyIndexSelector = createSelector(
  companiesByCategorySelector,
  currentCompanyIdSelector,
  (companies, currentCompanyId) => {
    if (currentCompanyId) {
      const index = companies.findIndex(company => company.id === currentCompanyId);

      return index > -1 ? index : null;
    }

    return null;
  },
);

const filteredCompaniesSelector = createSelector(
  filtersSelector,
  companiesSelector,
  (filters, companies) => {
    const {
      discount: { min, max },
      cardholderTypes,
      categories,
    } = filters;

    const compareMin = min >= MAX_DISCOUNT ? MAX_DISCOUNT : min;
    const compareMax = max >= MAX_DISCOUNT ? 100 : max;

    return Object.keys(companies)
      .filter((key) => {
        if (!cardholderTypes.length && !categories.length) {
          return false;
        }

        const company = companies[key];

        if (!((company.minDiscount >= compareMin
          || company.minDiscount === 0)
          && company.maxDiscount <= compareMax
        )) {
          return false;
        }

        if (!cardholderTypes.length) {
          return categories.includes(company.categoryId);
        }
        if (!categories.length) {
          return cardholderTypes.some(ct => company.cardholderTypes.includes(ct));
        }

        return categories.includes(company.categoryId)
          && cardholderTypes.some(ct => company.cardholderTypes.includes(ct));
      })
      .map((key) => {
        const company = companies[key];

        return ({
          ...company,
          coordinates: [company.geoData[1], company.geoData[0]],
        });
      });
  },
);

const currentFilteredCompanyIndexSelector = createSelector(
  currentCompanyIdSelector,
  filteredCompaniesSelector,
  (currentCompanyId, filteredCompanies) => {
    if (currentCompanyId) {
      const index = filteredCompanies.findIndex(company => company.id === currentCompanyId);

      return index > -1 ? index : null;
    }

    return null;
  },
);

// Structure Selectors
const categoriesStructureSelector = createStructuredSelector({
  categories: categoriesSelector,
  loadedCategoriesIds: loadedCategoriesIdsSelector,
  currentCategoryId: currentCategoryIdSelector,
  loading: loadingSelector,
});

const companiesStructureSelector = createStructuredSelector({
  companies: companiesByCategorySelector,
  currentCompanyId: currentCompanyIdSelector,
  currentCompanyIndex: currentCompanyIndexSelector,
  loading: loadingSelector,
});

const filtersStructureSelector = createStructuredSelector({
  categories: categoriesSelector,
  cardholderTypes: cardholderTypesSelector,
  filters: filtersSelector,
});

const filteredCompaniesStructureSelector = createStructuredSelector({
  currentCompanyId: currentCompanyIdSelector,
  currentFilteredCompanyIndex: currentFilteredCompanyIndexSelector,
  filters: filtersSelector,
  filteredCompanies: filteredCompaniesSelector,
  cardholderTypes: cardholderTypesSelector,
  loading: loadingSelector,
});


export {
  currentCategoryIdSelector,
  currentCompanyIdSelector,

  currentCategorySelector,
  currentCompanyIndexSelector,

  categoriesStructureSelector,
  companiesStructureSelector,

  filtersStructureSelector,
  filteredCompaniesStructureSelector,
};
