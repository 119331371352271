/* eslint-disable react/no-array-index-key */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  compose,
  withState,
} from 'recompose';

import Map from '../Map/Map';

import {
  CardholderTypesType,
} from '../../propTypes';

import {
  setCurrentCompany,
} from '../../store/partners';

import generateWorkingHours from './generateWorkingHours';

class Company extends PureComponent {
  static propTypes = {
    company: PropTypes.shape({
      name: PropTypes.string.isRequired,
      workingHours: PropTypes.array,
      address: PropTypes.string.isRequired,
      phone: PropTypes.string,
      link: PropTypes.string,
      webSite: PropTypes.string,
      geoData: PropTypes.array,
      termsDiscount: PropTypes.string,
    }).isRequired,
    setCurrentCompany: PropTypes.func.isRequired,

    showMap: PropTypes.bool,
    showDiscountByCardholdersTypes: PropTypes.bool,
    rawCardholdersTypes: CardholderTypesType,

    isWorkingHoursOpen: PropTypes.bool.isRequired,
    toggleWorkingHours: PropTypes.func.isRequired,
  }

  static defaultProps = {
    showMap: true,
    showDiscountByCardholdersTypes: false,
    rawCardholdersTypes: [],
  }

  render() {
    const {
      setCurrentCompany,
      company: {
        name,
        workingHours = [],
        address,
        phone: rawPhone,
        link,
        geoData = [],
        maxDiscount,
        minDiscount,
        cardholderTypes: companyCardholdersTypes,
        termsDiscount,
        webSite,
      },
      showMap,
      showDiscountByCardholdersTypes,
      rawCardholdersTypes,
      isWorkingHoursOpen,
      toggleWorkingHours,
    } = this.props;

    let phone = null;
    if (rawPhone) {
      phone = rawPhone
        .replace(/[()]/g, '')
        .replace(/-/g, ' ');

      if (phone[0] === '8') {
        phone = `8 ${phone}`;
      } else if (phone[0] !== 8 && phone[0] !== '+') {
        phone = `+7 ${phone}`;
      }
    }

    const {
      workingHoursNode,
      workText,
      isWorkingNow,
    } = generateWorkingHours(workingHours);

    const workingHourseToggleClass = cn({
      Company__workingHourseToggle: true,
      Company__workingHourseToggle_open: isWorkingHoursOpen,
      Company__workingHourseToggle_working: isWorkingNow,
    });

    let discountsListNode = null;
    if (showDiscountByCardholdersTypes) {
      discountsListNode = rawCardholdersTypes
        .filter(cardholderType => companyCardholdersTypes.includes(cardholderType.id))
        .map((cardholderType, index) => (
          <p
            key={index}
            className="discount"
          >
            <span className="discount__name">{cardholderType.name}</span>
            <span className="discount__blank">{' '}</span>
            <span className="discount__number">
              {maxDiscount === minDiscount
                ? `${maxDiscount}%`
                : `от ${minDiscount}% до ${maxDiscount}%`
              }
            </span>
          </p>
        ));
    }

    return (
      <div className="Company">
        <h4 className="h4 Company__title">
          {name}
          <button
            className="Company__close"
            onClick={() => setCurrentCompany(null)}
          />
        </h4>

        <div className="Company__wrapper">
          <div className="Company__data">
            <div className="Company__info">
              {maxDiscount && !showDiscountByCardholdersTypes &&
                <div className="Company__discount">
                  {`Скидка до ${maxDiscount}% в зависимости от категории льготника`}
                </div>
              }
              {discountsListNode && showDiscountByCardholdersTypes &&
                <div className="Company__discount">
                  Скидка предусмотрена для категорий:
                  {discountsListNode}
                </div>
              }

              {termsDiscount &&
                <div className="Company__terms">
                  Условия предоставления скидок:
                  <br />
                  {termsDiscount}
                </div>
              }

              {workingHoursNode &&
                <div>
                  <span
                    tabIndex="-1"
                    role="button"
                    aria-pressed={isWorkingHoursOpen}
                    className={workingHourseToggleClass}
                    onClick={() => toggleWorkingHours(!isWorkingHoursOpen)}
                    onKeyDown={() => toggleWorkingHours(!isWorkingHoursOpen)}
                  >
                    {workText}
                  </span>

                  {isWorkingHoursOpen &&
                    <div className="Company__workingHours">
                      {workingHoursNode}
                    </div>
                  }
                </div>
              }
              {address &&
                <div className="Company__adress">
                  {address}
                </div>
              }
              {phone &&
                <div className="Company__phone">
                  {phone}
                </div>
              }
              {link &&
                <div className="Company__link">
                  {link}
                </div>
              }
              {webSite &&
              <div className="Company__website">
                <a href={`http://${webSite}`} className="mos-link" target="_blank">{webSite}</a>
              </div>
              }
            </div>

            {(showMap && geoData.length > 0) &&
              <div className="Company__map">
                <Map
                  className="Company__mapContainer"
                  center={[geoData[1], geoData[0]]}
                  zoom={16}
                  showCenterMarker
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const wrapper = compose(
  connect(
    null,
    dispatch => bindActionCreators({
      setCurrentCompany,
    }, dispatch),
  ),
  withState('isWorkingHoursOpen', 'toggleWorkingHours', true),
);

export default wrapper(Company);
