import { combineReducers } from 'redux';
import partners from './partners';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer() {
  return combineReducers({
    partners,
  });
}
