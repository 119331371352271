import $ from 'jquery';
import getNotaCaptcha from './recaptchaNota';
import scrollToHeight from './scrollToHeight';

const MESSAGE_BLOCK_ACTIVE_CLASS = 'services-response_is-active';
const MESSAGE_BLOCK_VISIBLE_CLASS = 'services-response_is-visible';
const SLIDE_DOWN = 'SLIDE_DOWN';
const FADE_IN = 'FADE_IN';
function scrollToServiceResponse() {
  const servicesResponseTop = $('.services-response').offset().top;
  const margin = 24;
  scrollToHeight(servicesResponseTop - margin);
}

function insertText(messageNode, statusText) {
  messageNode.find('p').empty().append(statusText);
}

function createMessageNodeAndInsertText(statusCode, statusText) {
  const messageNode = $(`#message__${statusCode}`);
  if (statusCode !== 29) {
    insertText(messageNode, statusText);
  }
  return messageNode;
}

function showStatusMessage(message, method) {
  message.addClass(MESSAGE_BLOCK_ACTIVE_CLASS);
  if (method === FADE_IN) {
    message.fadeIn();
  } else {
    message.slideDown();
  }
}

function showMessage(serverResponseCode, applicationNumber, message, studentIsFound, serviceCode) {
  let messageBlock = $(`#message__${serverResponseCode}`);
  const transportNumber = $('#application-number');

  if (applicationNumber) transportNumber.text(applicationNumber);

  if (serverResponseCode === 200 && message && serviceCode === 'order-state') {
    // TODO: MRT-19 Костыль - переделать!
    if (message !== 'Социальная карта изготовлена') {
      messageBlock.removeClass('services-response_success').addClass('services-response_error');
    } else {
      messageBlock.removeClass('services-response_error').addClass('services-response_success');
    }
    messageBlock.text(message);
  }

  if (studentIsFound !== undefined) {
    const studentRegisterBlock = studentIsFound ? 1 : 0;

    messageBlock = $(`#message__${studentRegisterBlock}`);
  }

  // TODO: Костыль RMD-2519 MRT-19
  if ([500].indexOf(serverResponseCode) !== -1) {
    messageBlock = $('#message__999');
  }

  if ([400].indexOf(serverResponseCode) !== -1) {
    messageBlock = $('#message__400').html(message);
  }

  if ($(`.${MESSAGE_BLOCK_ACTIVE_CLASS}`).length) {
    $(`.${MESSAGE_BLOCK_ACTIVE_CLASS}`).each((e, item) => {
      const $target = $(item);

      $target.removeClass(MESSAGE_BLOCK_ACTIVE_CLASS);
      $target.fadeOut(400, () => {
        messageBlock.addClass(MESSAGE_BLOCK_ACTIVE_CLASS);
        messageBlock.fadeIn();
      });
    });
  } else {
    messageBlock.addClass(MESSAGE_BLOCK_ACTIVE_CLASS);
    messageBlock.slideDown();
  }
  scrollToServiceResponse();
}

function checkStatusShowMessage(serverResponseCode, response) {
  const cardStatusWrapper = $('#card-status-wrapper');
  const transportWrapper = $('#transport-wrapper');
  const messageItem = $('.services-response__text');

  messageItem.hide();
  cardStatusWrapper.removeClass(MESSAGE_BLOCK_VISIBLE_CLASS);
  transportWrapper.removeClass(MESSAGE_BLOCK_VISIBLE_CLASS);

  if (serverResponseCode === 200 && response) {
    const {
      card_status_code: cardStatusCode,
      card_status: cardStatus,
      privilege_choose_status_code: privilegeChooseStatusCode,
      privilege_choose_status: privilegeChooseStatus,
      auto_recreate_status_code: autoRecreateStatusCode,
      auto_recreate_status: autoRecreateStatus,
      contact_info_status_code: contactInfoStatusCode,
      contact_info_status: contactInfoStatus,
    } = response;
    const statuses = {
      [cardStatusCode]: cardStatus,
      [privilegeChooseStatusCode]: privilegeChooseStatus,
      [autoRecreateStatusCode]: autoRecreateStatus,
      [contactInfoStatusCode]: contactInfoStatus,
    };
    const correctNumbersForCardStatus = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 21, 22, 23];
    const correctNumbersForPrivilegesChoose = [12, 13, 15, 17, 20];

    const statusCodes = Object.keys(statuses);
    // eslint-disable-next-line max-len
    const isStatusArea = $.inArray(cardStatusCode, correctNumbersForCardStatus) !== -1
      || $.inArray(autoRecreateStatusCode, correctNumbersForCardStatus) !== -1;
    // eslint-disable-next-line max-len
    const isTransportArea = $.inArray(privilegeChooseStatusCode, correctNumbersForPrivilegesChoose) !== -1;
    const messageNodes = statusCodes.map((statusCode) => (
      createMessageNodeAndInsertText(statusCode, statuses[statusCode])
    ));
    if (isStatusArea) {
      cardStatusWrapper.addClass(MESSAGE_BLOCK_VISIBLE_CLASS);
    }
    if (isTransportArea) {
      transportWrapper.addClass(MESSAGE_BLOCK_VISIBLE_CLASS);
    }

    if ($(`.${MESSAGE_BLOCK_ACTIVE_CLASS}`).length) {
      $(`.${MESSAGE_BLOCK_ACTIVE_CLASS}`).each((e, item) => {
        const $target = $(item);

        $target.removeClass(MESSAGE_BLOCK_ACTIVE_CLASS);
        $target.fadeOut(400, () => {
          messageNodes.forEach((messageNode) => showStatusMessage(messageNode, FADE_IN));
        });
      });
    } else {
      messageNodes.forEach((messageNode) => showStatusMessage(messageNode, SLIDE_DOWN));
    }
  } else {
    if (serverResponseCode >= 400 && serverResponseCode < 500) {
      $('#message__400').slideDown();
    }
    if (serverResponseCode === 500) {
      $('#message__500').slideDown();
    }
    if (serverResponseCode === 92) {
      $('#message__92').slideDown();
    }
  }
  scrollToServiceResponse();
}
function clearForm(form, onlyCaptcha = false) {
  const captcha = $('#captcha-input');
  if (form) {
    if (onlyCaptcha && captcha) {
      captcha.val('');
    } else {
      $(form)
        .trigger('reset')
        .find('input').val(null);
      captcha.val('');
    }
  }
}

export const servicesFormResponse = (response, serviceCode, form) => {
  const {
    uuid,
    status,
    found,
    applicationNumber,
    statusMessage,
    statusText,
  } = response;

  if (status === 200) {
    showMessage(status, applicationNumber, statusMessage, found, serviceCode);
    clearForm(form);
  }

  // Новое API Обратной связи - /api/feedback/v2/frontend/json/ru/support/
  if (uuid) {
    showMessage(201, uuid, statusMessage, found, serviceCode);
    clearForm(form);
  } else if (status !== 200) {
    const { responseJSON } = response;
    const errorCode = responseJSON?.errorCode
      ? responseJSON.errorCode
      : statusText;

    if (status === 300) {
      showMessage(errorCode, null, errorCode);
    } else if (status === 400 && responseJSON.error && responseJSON.data) {
      showMessage(400, null, responseJSON.data.message);
    } else if (responseJSON && responseJSON.errors && responseJSON.errors.captcha) {
      showMessage(92, null, errorCode);
    } else {
      showMessage(status, null, errorCode);
    }

    clearForm(form, true);
  }

  getNotaCaptcha();
};

export const checkStatusFormResponse = (response, form) => {
  const {
    code,
    data,
    status,
    responseJSON,
  } = response;
  console.log('response', response);
  if ((code === 'SUCCESS') && data) {
    checkStatusShowMessage(200, data);
    clearForm(form);
  } else {
    if (responseJSON && responseJSON.errors && responseJSON.errors.captcha) {
      checkStatusShowMessage(92, null);
    } else {
      checkStatusShowMessage(status, null);
    }
    clearForm(form, true);
  }

  getNotaCaptcha();
};
