import $ from 'jquery';

let captchaIsReloading = false;

export default function getNotaCaptcha() {
  const img = document.querySelector('#captcha-image');

  if (!img) {
    return;
  }

  const xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      const url = window.URL || window.webkitURL;
      img.src = url.createObjectURL(this.response);
      $('.captcha-image-container').addClass('nota-captcha-is-shown');
      $('#reload-captcha').css('display', 'inline-block');
      captchaIsReloading = false;
    }

    if (this.readyState === 4 && this.status !== 200) {
      $('#reload-captcha').css('display', 'inline-block');
      captchaIsReloading = false;
    }

    if (this.readyState === this.HEADERS_RECEIVED) {
      const captchaCodeHeader = xhr.getResponseHeader('Captcha-Code');
      $('#captcha-input').attr('data-captcha-code', captchaCodeHeader);
    }
  };

  xhr.open('GET', '/api/users/v1/frontend/json/captcha');
  xhr.responseType = 'blob';
  xhr.send();
}

$('#reload-captcha').on('click', (event) => {
  event.preventDefault();

  if (captchaIsReloading) {
    return false;
  }

  captchaIsReloading = true;
  return getNotaCaptcha();
});
